@font-face {
  font-family: 'Branding';
  src: url($assets-path + 'branding-bold.woff2') format('woff2'), url($assets-path + 'branding-bold.woff') format('woff');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: 'Branding';
  src: url($assets-path + 'branding-black.woff2') format('woff2'), url($assets-path + 'branding-black.woff') format('woff');
  font-display: swap;
  font-weight: 900;
}

@font-face {
  font-family: 'Branding';
  src: url($assets-path + 'branding-semibold.woff2') format('woff2'), url($assets-path + 'branding-semibold.woff') format('woff');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'Branding';
  src: url($assets-path + 'branding-medium.woff2') format('woff2'), url($assets-path + 'branding-medium.woff') format('woff');
  font-display: swap;
  font-weight: 400;
}

#pageHeader {
  backdrop-filter: blur(20px);
  margin: 15px;
  width: calc(100% - 30px);
  border-radius: $border-radius;
}

@media (min-width: $nav-breakpoint + 1) {
  #menuMain {
    position: absolute;
    bottom: -2px;
  }
}

@media (max-width: $nav-breakpoint) {
  #menuMain {
    right: unset !important;
  }
}

.mainCallToActionButtons {
  border-left: 1px solid #868686;
  padding-left: 10px;
  margin-left: 0;
}

/* Footer */
.pageFooter {
  display: flex;
  flex-direction: column;
  max-width: $container-max-width;
  margin: auto;
}

.footerBox ul {
  display: flex;
  flex-direction: row;
  li {
    margin-right: 1rem;
  }
}

body {
  margin-top: 0 !important;
}

.page-has-banner .carouselSlide {
  height: 500px;
  img,
  .carouselSlideTitle {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.page-no-banner .carouselSlide {
  height: 100px;
}

.post .appealActionsWrapper {
  top: 70px;
}

.headerContent {
  font-size: 15px;
}